import React from "react";
import Header from "../Components/header";

export default function Portfolio() {
    return (
        <>
            {<Header/>}

            <main className="">
                <section className="portfolio">
                    <div className="container">
                        <h1 className="text-center text-white">Portfolio</h1>

                        <div className="row mt-5">
                            <div className="mb-3 col-md-4 col-12">
                                <div className="portfolio-box text-center">
                                    <img className="img-fluid mb-4" src="/img/portfolio/arb.png" alt="Arbitrium"/>
                                    <h6 className="fw-bold text-white mb-0">Arbitrum</h6>
                                </div>
                            </div>
                            <div className="mb-3 col-md-4 col-12">
                                <div className="portfolio-box text-center">
                                    <img className="img-fluid mb-4" src="/img/portfolio/theta.png" alt="Theta"/>
                                    <h6 className="fw-bold text-white mb-0">Theta Network</h6>
                                </div>
                            </div>
                            <div className="mb-3 col-md-4 col-12">
                                <div className="portfolio-box text-center">
                                    <img className="img-fluid mb-4" src="/img/portfolio/high.jpeg" alt="High Street"/>
                                    <h6 className="fw-bold text-white mb-0">High Street</h6>
                                </div>
                            </div>

                            <div className="mb-3 col-md-4 col-12">
                                <div className="portfolio-box text-center">
                                    <img className="img-fluid mb-4" src="/img/portfolio/om.jpeg" alt="Mantra"/>
                                    <h6 className="fw-bold text-white mb-0">Mantra</h6>
                                </div>
                            </div>
                            <div className="mb-3 col-md-4 col-12">
                                <div className="portfolio-box text-center">
                                    <img className="img-fluid mb-4" src="/img/portfolio/saga.png" alt="Saga"/>
                                    <h6 className="fw-bold text-white mb-0">Saga</h6>
                                </div>
                            </div>
                            <div className="mb-3 col-md-4 col-12">
                                <div className="portfolio-box text-center">
                                    <img className="img-fluid mb-4" src="/img/portfolio/unfi.png"
                                         alt="Unfi Protocol DAO"/>
                                    <h6 className="fw-bold text-white mb-0">Unfi Protocol DAO</h6>
                                </div>
                            </div>

                            <div className="mb-3 col-md-4 col-12">
                                <div className="portfolio-box text-center">
                                    <img className="img-fluid mb-4" src="/img/portfolio/vechain.png" alt="VeChain"/>
                                    <h6 className="fw-bold text-white mb-0">VeChain</h6>
                                </div>
                            </div>
                            <div className="mb-3 col-md-4 col-12">
                                <div className="portfolio-box text-center">
                                    <img className="img-fluid mb-4" src="/img/portfolio/sei.png" alt="Sei"/>
                                    <h6 className="fw-bold text-white mb-0">Sei</h6>
                                </div>
                            </div>
                            <div className="mb-3 col-md-4 col-12">
                                <div className="portfolio-box text-center">
                                    <img className="img-fluid mb-4" src="/img/portfolio/mav.png" alt="Maverick Protocol"/>
                                    <h6 className="fw-bold text-white mb-0">Maverick Protocol</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
