import React from "react";
import Header from "../Components/header";

export default function Contact() {
    return (
        <>
            {<Header/>}

            <main className="">
                <section className="contact">
                    <div className="container">
                        <h1 className="text-center text-white">Contact Us</h1>

                        <h6 className="fw-bold text-white mb-5">You can find us here.</h6>

                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="contact-box">
                                    <div className="row">
                                        <div className="col-md-2 col-12 text-start mb-3 mb-md-0 text-md-center">
                                            <i className="fa-regular fa-envelope fs-2"></i>
                                        </div>
                                        <div className="col-md-10 col-12">
                                            <h6 className="fw-bold">Email</h6>
                                            <p><a className="text-white"
                                                  href="mailto:info@x1.capital">info@x1.capital</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="contact-box">
                                    <div className="row">
                                        <div className="col-md-2 col-12 text-start mb-3 mb-md-0 text-md-center">
                                            <i className="fa-brands fa-telegram fs-2"></i>
                                        </div>
                                        <div className="col-md-10 col-12">
                                            <h6 className="fw-bold">Telegram</h6>
                                            <p><a className="text-white"
                                                  href="https://t.me/X1Capital">click here -></a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="contact-box">
                                    <div className="row">
                                        <div className="col-md-2 col-12 text-start mb-3 mb-md-0 text-md-center">
                                            <i className="fa-brands fa-x-twitter fs-2"></i>
                                        </div>
                                        <div className="col-md-10 col-12">
                                            <h6 className="fw-bold">X</h6>
                                            <p><a className="text-white"
                                                  href="https://x.com/X1_capital">click here -></a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </>
    );
}
