import React from "react";
import Header from "../Components/header";

export default function HomePage() {
    return (
        <>
            {<Header/>}

            <main className="">
                <section className="welcome">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 vertical-center">
                                <div className="inner-box">
                                    <h1 className="display-4 fw-semibold">
                                        Start with
                                        <br/>
                                        <span className="ms-5 fw-medium"> X1 Capital{' '}<sup>🚀</sup>
                                      </span>
                                    </h1>
                                    <p className="text-white mt-5">
                                        Growth of a portfolio management company depends not only on the quality of its
                                        products or employees. It also depends on the entire ecosystem surrounding it. A
                                        growing company fosters fertile ecosystems which in turn feeds back into the
                                        company generating sustainable, long-term growth.
                                    </p>
                                    <a className="btn custom_btn" href="/contact">
                                        <span className="me-3">contact with us</span>
                                        <svg
                                            className="size-6"
                                            fill="none"
                                            height="22"
                                            stroke="currentColor"
                                            strokeWidth="{1.5}"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            {' '}
                                            <path
                                                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            {' '}
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 hero">
                                <h2>
                                    X
                                </h2>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
