import React from "react";
import { NavLink } from 'react-router-dom';

window.onload = function () {
    document.addEventListener("click", function (event) {
        // if the clicked element isn't child of the navbar, you must close it if is open
        if (!event.target.closest(".navbar") && document.getElementById("navbarSupportedContent").classList.contains("show")) {
            document.getElementById("hamburger_menu_button").click();
        }
    });
};

export default function Header() {
    return (
        <header className="bg-transparent fixed-top">
            <nav className="navbar navbar-expand-lg navbar-dark">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src="/img/logo.png" alt="X1 Capital" height={40}/>
                    </a>
                    <button
                        id="hamburger_menu_button"
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink activeClassName="active" to="/" className="nav-link">
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink activeClassName="active" to="/about" className="nav-link">
                                    About
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink activeClassName="active" to="/portfolio" className="nav-link">
                                    Portfolio
                                </NavLink>
                            </li>
                            <li className="nav-item ms-lg-3 c-btn">
                                <NavLink activeClassName="active" to="/contact" className="btn btn-outline-light">
                                    contact us
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}
