import React from "react";
import Header from "../Components/header";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

export default function About() {
    return (
        <>
            {<Header/>}

            <main>
                <section className="about min-vh-100">
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-md-4 col-12 h-100">
                                <div className="row h-100">
                                    <div className="col-md-6 col-12 position-relative">
                                        <div className="start-opacity w-75"></div>
                                        <Swiper
                                            slidesPerView={3}
                                            height={600}
                                            spaceBetween={30}
                                            direction={'vertical'}
                                            loop={true}
                                            responsive={true}
                                            speed={2000}
                                            freeMode={true}
                                            autoplay={{
                                                delay: 0.5,
                                                disableOnInteraction: false,
                                            }}
                                            modules={[Autoplay]}
                                            className="partners"
                                        >
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/theta.png" alt=""/></SwiperSlide>
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/high.jpeg" alt=""/></SwiperSlide>
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/om.jpeg" alt=""/></SwiperSlide>
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/saga.png" alt=""/></SwiperSlide>
                                        </Swiper>

                                        <Swiper
                                            slidesPerView={3}
                                            height={150}
                                            spaceBetween={30}
                                            loop={true}
                                            responsive={true}
                                            speed={2000}
                                            freeMode={true}
                                            autoplay={{
                                                delay: 0.5,
                                                disableOnInteraction: false,
                                            }}
                                            modules={[Autoplay]}
                                            className="partners_m"
                                        >
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/theta.png" alt=""/></SwiperSlide>
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/high.jpeg" alt=""/></SwiperSlide>
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/om.jpeg" alt=""/></SwiperSlide>
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/saga.png" alt=""/></SwiperSlide>
                                        </Swiper>

                                        <Swiper
                                            slidesPerView={3}
                                            height={150}
                                            spaceBetween={30}
                                            loop={true}
                                            responsive={true}
                                            speed={2000}
                                            freeMode={true}
                                            autoplay={{
                                                delay: 0.5,
                                                disableOnInteraction: false,
                                                reverseDirection: true,
                                            }}
                                            modules={[Autoplay]}
                                            className="partners_m partners-pc"
                                        >
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/sei.png" alt=""/></SwiperSlide>
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/unfi.png" alt=""/></SwiperSlide>
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/vechain.png" alt=""/></SwiperSlide>
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/arb.png" alt=""/></SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className="col-md-6 col-12 pt-5 position-relative">
                                        <div className="start-opacity mt-5"></div>
                                        <Swiper
                                            slidesPerView={3}
                                            height={600}
                                            spaceBetween={30}
                                            direction={'vertical'}
                                            loop={true}
                                            responsive={true}
                                            speed={2000}
                                            freeMode={true}
                                            autoplay={{
                                                delay: 0.5,
                                                disableOnInteraction: false,
                                                reverseDirection: true,
                                            }}
                                            modules={[Autoplay]}
                                            className="partners partners-pc"
                                        >
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/sei.png" alt=""/></SwiperSlide>
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/unfi.png" alt=""/></SwiperSlide>
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/vechain.png" alt=""/></SwiperSlide>
                                            <SwiperSlide><img className="img-fluid p-image w-75 mb-5" src="/img/portfolio/arb.png" alt=""/></SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 col-12">
                                <h1>Capitalizing on the frontier of tech</h1>
                                <p>X1 Capital is a blockchain consultancy and investment partnership. We believe that blockchain technology and cryptocurrencies will drastically change the way industries and market operating on a global scale.
                                    <br/>
                                    <br/>
                                    Therefore, we are dedicated to helping young start-ups and more established projects accelerate their growth and thrive in this industry. We develop unique growth strategies focused on the key aspects of blockchain business by leveraging key interactions and our experience in this industry.</p>
                                <div>
                                    <a className="btn custom_btn" href="/contact">
                                        <span className="me-3">contact with us</span>
                                        <svg
                                            className="size-6"
                                            fill="none"
                                            height="22"
                                            stroke="currentColor"
                                            strokeWidth="{1.5}"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            {' '}
                                            <path
                                                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            {' '}
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
